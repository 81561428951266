import TreeView, { INode } from 'react-accessible-treeview';
import { MinusIcon, PlusIcon, TouchpadIcon } from 'lucide-react';
import { getPathToNode } from '@/lib/tree-utils';

import { useSetRecoilState } from 'recoil';
import { selectedPathAtom } from '@/state/selected-path';

export interface TreeProps {
	data: INode[];
}

export function Tree({ data }: TreeProps) {
	const setSelectedPath = useSetRecoilState(selectedPathAtom);

	return (
		<TreeView
			data={data}
			className="basic"
			aria-label="basic example tree"
			nodeRenderer={({ element, getNodeProps, level, handleSelect }) => {
				const nodeProps = getNodeProps();
				return (
					<div {...nodeProps} style={{ paddingLeft: 20 * (level - 1) }}>
						{element.isBranch ? (
							nodeProps.className.indexOf('--expanded') !== -1 ? (
								<MinusIcon size={16} />
							) : (
								<PlusIcon size={16} />
							)
						) : level === 3 ? (
							<div className="p-2"></div>
						) : (
							<TouchpadIcon size={16} />
						)}
						<span className="pl-2">{element.name}</span>
					</div>
				);
			}}
			onNodeSelect={({ element, isSelected }) => {
				const path = getPathToNode(element, data);
				console.log({ element, path, data });

				setSelectedPath(path);
			}}
		/>
	);
}
