import config from '@/config';
import { selectedPathAtom } from '@/state/selected-path';
import { useRecoilValue } from 'recoil';

export const useSelectedPathText = () => {
	const selectedPath = useRecoilValue(selectedPathAtom);

	let selectedPathText: string = selectedPath
		.filter((item) => item.name)
		.map((item) => item.name)
		.join(' / ');
	selectedPathText = selectedPathText ?? config.companyName;

	return selectedPathText;
};
