import ictImage from './../../mocks/chat.png'

const ict = () => {
	return (
		<div>
			<img alt="ict" src={ ictImage } />
		</div>
	)
}

export default ict;