const defaultConfig = {
	debug: false,

	clientApiUrl: 'https://bcp-ot-mvp-cmdbserviceapi.azurewebsites.net',
	authApiUrl: 'https://bcp-ot-mvp-securitytokenserviceapi.azurewebsites.net/api/V1',

	storageId: 'ict',
	applicationId: 'app',
	userId: 'user',

	companyName: 'ICT',
}

const config = {
	debug: window.config?.debug ?? defaultConfig.debug,

	clientApiUrl: window.config?.clientApiUrl ?? defaultConfig.clientApiUrl,
	authApiUrl: window.config?.authApiUrl ?? defaultConfig.authApiUrl,

	storageId: window.config?.storageId ?? defaultConfig.storageId,
	applicationId: window.config?.applicationId ?? defaultConfig.applicationId,
	userId: window.config?.userId ?? defaultConfig.userId,

	companyName: window.config?.companyName ?? defaultConfig.companyName
}


export default config;
