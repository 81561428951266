import React from 'react';
import { cn } from '@/lib/utils';


interface AssetStatusProps {
	className: string;
	name: string;
	value: number;
}

const valueClasses = 'text-3xl font-semibold tracking-tight';

const AssetStatus = ( { className, name, value } : AssetStatusProps ) => {
	return (
		<div className="flex justify-between items-end">
			<span className="text-content-grey/60 text-lg tracking-wide" >{name}</span>
			<span className={cn(valueClasses, className)}>{value}</span>
		</div>
	);
}

export default AssetStatus;