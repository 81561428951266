import React from 'react';

const OTCommunity = () => {
	return (
		<>
			<h1>OT Community</h1>
		</>
	)
}

export default OTCommunity;