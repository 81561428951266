const hardwareAssets: any = {
	"data": [
	  {
		"id": 239,
		"additionalInfo": "",
		"itemNrOrPcName": "13T3, 13T4",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 401,
		  "deviceDescription": "Time Delay Relay 0.1 s → 120h",
		  "manufacturer": {
			"id": 1,
			"name": "Omron",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "H3DK",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 248,
		"additionalInfo": "",
		"itemNrOrPcName": "RX1 / K2.1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 410,
		  "deviceDescription": "SIRIUS safety relay 2S/1Ö, 24V DC 1 NO/1 NC",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "3SE6806-2CD00",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "3SK2122-AA10",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 249,
		"additionalInfo": "",
		"itemNrOrPcName": "RX2 / K1.0",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 411,
		  "deviceDescription": "SIRIUS safety relay 24 V DC 2 NO",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "3TK2828-1BB40",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "3SK1121-1CB42 - (no signaling contact, needs to be added when replaced with an additional part)",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 250,
		"additionalInfo": "",
		"itemNrOrPcName": "K1.7",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder HMI",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 412,
		  "deviceDescription": "\"SIMATIC MP 277 8\"\" TOUCH\"",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6AV6643-0CB01-1AX1",
		  "deviceType": {
			"id": 2,
			"name": "HMI Panel",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 251,
		"additionalInfo": "",
		"itemNrOrPcName": "A1, A2",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 413,
		  "deviceDescription": "Motion control stepper motor drive SD315",
		  "manufacturer": {
			"id": 3,
			"name": "Schneider Electric",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "SD315DN10B400",
		  "deviceType": {
			"id": 3,
			"name": "MOTOR",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 252,
		"additionalInfo": "",
		"itemNrOrPcName": "U2.0",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder Motors",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 415,
		  "deviceDescription": "SINAMICS S120 Smart Line Module input: 380-480 V 3 AC, 50/60 Hz output: 600 V DC, 8.3 A, 5 kW",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6SL3130-6AE15-0AB0",
		  "deviceType": {
			"id": 3,
			"name": "MOTOR",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6SL3130-6AE15-0AB1",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 253,
		"additionalInfo": "",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 431,
		  "deviceDescription": "ET200M Active Backplane, Bus module",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7195-7HB00-0XA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 254,
		"additionalInfo": "SLOT 2 CPU - ET200S",
		"itemNrOrPcName": "K1.0",
		"parentAsset": undefined,
		"firmwareVersion": "2.7",
		"macAddress": "",
		"networkAddress": "DP: 2, 192.168.80.2",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "Yes",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "No spare available at Owens",
		"mainSystemPart": "Acelli Kokersnijder PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 422,
		  "deviceDescription": "ET200S IM151-8 PN/DP CPU",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7151-8AB00-0AB0",
		  "deviceType": {
			"id": 5,
			"name": "PLC CPU",
			"description": null
		  },
		  "additionalInfo": "SLOT 2 CPU - ET200S",
		  "latestAvailableFirmwareVersion": "2.7",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "No spare available at Owens",
		  "successorType": "6ES7151-8AB01-0AB0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 255,
		"additionalInfo": "SLOT 2",
		"itemNrOrPcName": "2U2",
		"parentAsset": undefined,
		"firmwareVersion": "-",
		"macAddress": "",
		"networkAddress": "DP: 7    MPI: 2",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "Yes",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "No 1-for-1 replacement, engineering effort is required to impliment the replacement CPU. 6ES7315-2FJ14-0AB0  and 6ES7317-2FJ14-0AB0 available in Owens spares Algemeen, Trima and Pesmel.",
		"mainSystemPart": "STAMM1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 423,
		  "deviceDescription": "S7-300 CPU315-2 DP",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7315-2AF02-0AB0",
		  "deviceType": {
			"id": 5,
			"name": "PLC CPU",
			"description": null
		  },
		  "additionalInfo": "SLOT 2",
		  "latestAvailableFirmwareVersion": "-",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "No 1-for-1 replacement, engineering effort is required to impliment the replacement CPU. 6ES7315-2FJ14-0AB0  and 6ES7317-2FJ14-0AB0 available in Owens spares Algemeen, Trima and Pesmel.",
		  "successorType": "6ES7315-2AH14-0AB0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 256,
		"additionalInfo": "SLOT 3",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "V4.1",
		"macAddress": "",
		"networkAddress": "PB: 1, MPI: 12",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "Yes",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "6ES7416-2FN05-0AB0 available in spares Algemeen and Vits 4mtr 2e droger holdings. Some engineering required, as this is a F CPU and not a standalone CPU",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 425,
		  "deviceDescription": "S7-400 CPU 416-2 DP",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7416-2XK04-0AB0",
		  "deviceType": {
			"id": 5,
			"name": "PLC CPU",
			"description": null
		  },
		  "additionalInfo": "SLOT 3",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "6ES7416-2FN05-0AB0 available in spares Algemeen and Vits 4mtr 2e droger holdings. Some engineering required, as this is a F CPU and not a standalone CPU",
		  "successorType": "6ES7416-2XP07-0AB0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 257,
		"additionalInfo": "SLOT 5, 6, 9",
		"itemNrOrPcName": "K1.3, K1.3.1, N/A",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 426,
		  "deviceDescription": "ET200S DI8xDC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7131-4BF00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 5, 6, 9",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 258,
		"additionalInfo": "SLOT 7 - 8",
		"itemNrOrPcName": "K1.4, K1.5",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 427,
		  "deviceDescription": "ET200S DO8xDC24V/0.5A",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7132-4BF00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 7 - 8",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 259,
		"additionalInfo": "SLOT 4 POWER MODULE",
		"itemNrOrPcName": "K1.2.1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 428,
		  "deviceDescription": "ET200S PM-E DC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7138-4CA01-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 4 POWER MODULE",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 260,
		"additionalInfo": "SLOT 2 PROFIBUS DP COMM MODULE",
		"itemNrOrPcName": "K1.2",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "DP: 2",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 429,
		  "deviceDescription": "ET200S PB DP,DP master",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7138-4HA00-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 2 PROFIBUS DP COMM MODULE",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 261,
		"additionalInfo": "SLOT 2",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "DP: 5",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 430,
		  "deviceDescription": "ET200M IM153-1",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7153-1AA03-0XB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 2",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 262,
		"additionalInfo": "",
		"itemNrOrPcName": "GEEN",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 444,
		  "deviceDescription": "Profibus repeater",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7972-0AA01-0XA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6ES7972-0AA02-0XA0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 263,
		"additionalInfo": "SLOT 7",
		"itemNrOrPcName": "40U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 433,
		  "deviceDescription": "S7-300 SM321 DI32xDC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7321-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 6 - 7",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 264,
		"additionalInfo": "SLOT 6 - 8",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 433,
		  "deviceDescription": "S7-300 SM321 DI32xDC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7321-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 6 - 7",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 265,
		"additionalInfo": "SLOT 4, 6",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 433,
		  "deviceDescription": "S7-300 SM321 DI32xDC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7321-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 6 - 7",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 266,
		"additionalInfo": "SLOT 6 - 7",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 433,
		  "deviceDescription": "S7-300 SM321 DI32xDC24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7321-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 6 - 7",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 267,
		"additionalInfo": "SLOT 8",
		"itemNrOrPcName": "50U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 434,
		  "deviceDescription": "S7-300 SM322 DO32xDC24V/0.5A",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7322-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 8",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 268,
		"additionalInfo": "SLOT 9 - 10",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 434,
		  "deviceDescription": "S7-300 SM322 DO32xDC24V/0.5A",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7322-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 8",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 269,
		"additionalInfo": "SLOT 5",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "V2.0",
		"macAddress": "",
		"networkAddress": "100.231.11.12",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 445,
		  "deviceDescription": "S7-400 CP443-1",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6GK7443-1EX11-0XE0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 5",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6GK7443-1EX30-0XE0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 270,
		"additionalInfo": "SLOT 8",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 435,
		  "deviceDescription": "S7-300 SM331 AI8x12Bit",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7331-7KF02-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 4",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 271,
		"additionalInfo": "SLOT 4",
		"itemNrOrPcName": "20U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 435,
		  "deviceDescription": "S7-300 SM331 AI8x12Bit",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7331-7KF02-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 4",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 272,
		"additionalInfo": "SLOT 4",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "2.0",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 445,
		  "deviceDescription": "S7-400 CP443-1",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6GK7443-1EX11-0XE0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 5",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6GK7443-1EX30-0XE0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 273,
		"additionalInfo": "SLOT 9",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 436,
		  "deviceDescription": "S7-300 SM332 AO4x12Bit",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7332-5HD01-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 5",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 274,
		"additionalInfo": "SLOT 5 - 6",
		"itemNrOrPcName": "30U1, 32U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "MPI: 4, 3",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 437,
		  "deviceDescription": "S7-300 FM353 STEPPER",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7353-1AH01-0AE0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 5 - 6",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 275,
		"additionalInfo": "RACK",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 438,
		  "deviceDescription": "S7-400 UR2",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7400-1JA01-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "RACK",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 276,
		"additionalInfo": "SLOT 1",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 439,
		  "deviceDescription": "S7-400 PS407 10A",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7407-0KA01-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 1",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6ES7407-0KA02-0AA0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 277,
		"additionalInfo": "SLOT 6 - 9, 15",
		"itemNrOrPcName": "20U1, 24U1, 28U1, 32U1, 36U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 440,
		  "deviceDescription": "S7-400 DI32xDC 24V",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7421-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 6 - 9, 15",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6ES7421-1BL01-0AA0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 278,
		"additionalInfo": "SLOT 10 - 11",
		"itemNrOrPcName": "50U1, 54U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 441,
		  "deviceDescription": "S7-400 DO32xDC24V/0.5A",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7422-1BL00-0AA0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 10 - 11",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 279,
		"additionalInfo": "SLOT 12 - 13",
		"itemNrOrPcName": "70U1, 72U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 442,
		  "deviceDescription": "S7-400 AI8x14Bit",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7431-1KF10-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 12 - 13",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 280,
		"additionalInfo": "SLOT 14",
		"itemNrOrPcName": "80U1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 443,
		  "deviceDescription": "S7-400 AO8x13Bit",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6ES7432-1HF00-0AB0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 14",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 281,
		"additionalInfo": "SLOT 16",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "V3.1",
		"macAddress": "",
		"networkAddress": "PB: 2",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 446,
		  "deviceDescription": "S7-400 CP443-5 Ext",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6GK7443-5DX04-0XE0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 17",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6GK7443-5DX05-0XE0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 282,
		"additionalInfo": "SLOT 17",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "V6.0",
		"macAddress": "",
		"networkAddress": "PB: 2",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "MB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 446,
		  "deviceDescription": "S7-400 CP443-5 Ext",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "6GK7443-5DX04-0XE0",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 17",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "6GK7443-5DX05-0XE0",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 283,
		"additionalInfo": "SLOT 4 Weighing Module, SIWAREX U",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 447,
		  "deviceDescription": "S7-300 SIWAREX U-2",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "7MH4950-2AA01",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 7 Weighing Module, SIWAREX U",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "No successor",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 284,
		"additionalInfo": "SLOT 7 Weighing Module, SIWAREX U",
		"itemNrOrPcName": "",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "SVB1 PLC",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 447,
		  "deviceDescription": "S7-300 SIWAREX U-2",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "7MH4950-2AA01",
		  "deviceType": {
			"id": 6,
			"name": "PLC Other",
			"description": null
		  },
		  "additionalInfo": "SLOT 7 Weighing Module, SIWAREX U",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "No successor",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 285,
		"additionalInfo": "",
		"itemNrOrPcName": "13T1, 13T2",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 401,
		  "deviceDescription": "Time Delay Relay 0.1 s → 120h",
		  "manufacturer": {
			"id": 1,
			"name": "Omron",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "H3DK",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 286,
		"additionalInfo": "",
		"itemNrOrPcName": "K2(CR1), K3",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 450,
		  "deviceDescription": "Power contactor, AC-3 7 A, 3 kW / 400 V 1 NC",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "3RT1015-1AF02",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "3RT2015-1AF02",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 287,
		"additionalInfo": "",
		"itemNrOrPcName": "10K1, 11K1",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "STAMM1 Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 451,
		  "deviceDescription": "Power contactor, AC-3 9 A, 4 kW / 400 V 1 NO",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "3RT1016-1BB41",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "3RT2016-1BB41",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  },
	  {
		"id": 288,
		"additionalInfo": "",
		"itemNrOrPcName": "K2, K3",
		"parentAsset": undefined,
		"firmwareVersion": "",
		"macAddress": "",
		"networkAddress": "",
		"inUseOrSpare": "In Use",
		"actualSoftwareBackupAvailable": "",
		"slaOrSupportContract": "",
		"manufacturerDocumentationAvailable": "",
		"remarks": "",
		"mainSystemPart": "Acelli Kokersnijder Elec",
		"budgetPlantPart": "",
		"location": {
		  "id": 3,
		  "installationName": "Sample Data",
		  "installationNumber": "",
		  "description": "",
		  "locationType": undefined,
		  "parentNode": undefined,
		  "site": undefined,
		  "locationNodes": null,
		  "audits": null,
		  "servers": null,
		  "softwareAssets": null,
		  "hardwareAssets": []
		},
		"hardwareComponent": {
		  "id": 452,
		  "deviceDescription": "Surge suppressor, varistor 127.240 VAC 150.250 VDC",
		  "manufacturer": {
			"id": 2,
			"name": "Siemens",
			"description": null,
			"hardwareComponents": [],
			"softwareComponents": null
		  },
		  "typeNumber": "3RT1916-1BD00",
		  "deviceType": {
			"id": 1,
			"name": "Electrical",
			"description": null
		  },
		  "additionalInfo": "",
		  "latestAvailableFirmwareVersion": "",
		  "productLifecycle": null,
		  "endLifecyle": null,
		  "remarks": "",
		  "successorType": "3RT2916-1BD00",
		  "vilocifyComponent": null
		},
		"vilocifyComponent": null
	  }
	],
	"count": 42,
	"success": true,
	"message": "Successfully retreived 42 hardwareAssets."
  }

export default hardwareAssets;