import * as React from 'react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/shadcn-ui/button';
import { Input } from '@/components/shadcn-ui/input';
import { Label } from '@/components/shadcn-ui/label';
import { Icons } from '@/components/shadcn-ui/icons';
import { useNavigate } from 'react-router';
import config from '@/config';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const UserAuthForm = ({ className, ...props }: UserAuthFormProps) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const [storageId, setStorageId] = React.useState<string>();

	async function onSubmit(event: React.SyntheticEvent) {
		event.preventDefault();
		setIsLoading(true);

		config.storageId = storageId;
		config.companyName = storageId;

		setTimeout(() => {
			setIsLoading(false);
			navigate('/dashboard/assets');
		}, 1500);
	}

	const onStorageKeyInputChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setStorageId(e.target.value);
		},
		[]
	);

	return (
		<div className={cn('grid gap-6', className)} {...props}>
			<form onSubmit={onSubmit}>
				<div className="grid gap-2">
					<div className="grid gap-1">
						<Label className="sr-only" htmlFor="SecureStorageKey">
							SecureStorageKey
						</Label>
						<Input
							id="SecureStorageKey"
							placeholder="SecureStorageKey"
							autoCapitalize="none"
							autoCorrect="off"
							disabled={isLoading}
							onChange={onStorageKeyInputChange}
						/>
					</div>
					<Button disabled={isLoading}>
						{isLoading && (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						)}
						Login
					</Button>
				</div>
			</form>
			<div className="relative">
				<div className="absolute inset-0 flex items-center">
					<span className="w-full border-t" />
				</div>
				<div className="relative flex justify-center text-xs uppercase">
					<span className="bg-background px-2 text-muted-foreground">
						Or continue with
					</span>
				</div>
			</div>
			<Button variant="outline" type="button" disabled={isLoading}>
				{isLoading ? (
					<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
				) : (
					<Icons.Microsoft className="mr-2 h-4 w-4" />
				)}{' '}
				Sign in with Microsoft
			</Button>
		</div>
	);
};

export default UserAuthForm;
