import { LucideProps } from "lucide-react";

export const Logo = ( props: LucideProps ) => ( // not sure how to use these props here... 
	<svg version="1.1" x="0px" y="0px" viewBox="0 0 1421.79 314.05">
<g>
	<g>
		<path fill="#FFFFFF" d="M384.61,148.1c0-31.89,24.15-53.41,55.12-53.41c25.7,0,39.02,14.09,45.83,27.72l-22.91,10.83
			c-3.41-8.21-12.23-15.01-22.92-15.01c-16.1,0-28.02,13-28.02,29.88c0,16.88,11.92,29.88,28.02,29.88
			c10.68,0,19.51-6.81,22.92-15.02l22.91,10.84c-6.66,13.32-20.13,27.72-45.83,27.72C408.76,201.51,384.61,179.84,384.61,148.1z"/>
		<path fill="#FFFFFF" d="M542.64,94.69c31.58,0,55.12,21.98,55.12,53.41s-23.53,53.42-55.12,53.42s-55.27-21.99-55.27-53.42
			S511.05,94.69,542.64,94.69z M542.64,118.22c-17.18,0-28.18,13-28.18,29.88c0,16.72,10.99,29.88,28.18,29.88
			c17.03,0,28.02-13.16,28.02-29.88C570.66,131.23,559.67,118.22,542.64,118.22z"/>
		<path fill="#FFFFFF" d="M677.14,199.66l-43.66-62.39v62.39h-26.63V96.39h27.4l41.8,59.45V96.39h26.78v103.27H677.14z"/>
		<path fill="#FFFFFF" d="M740.1,199.66v-80.04h-28.95V96.39h84.38v23.22h-28.8v80.04H740.1z"/>
		<path fill="#FFFFFF" d="M803.86,199.66V96.39h26.63v103.27H803.86z"/>
		<path fill="#FFFFFF" d="M915,199.66l-43.66-62.39v62.39h-26.63V96.39h27.4l41.8,59.45V96.39h26.79v103.27H915z"/>
		<path fill="#FFFFFF" d="M954.9,96.39H982v60.54c0,12.23,6.5,21.05,20.9,21.05c14.24,0,20.74-8.82,20.74-21.05V96.39h27.1v61.31
			c0,25.7-14.71,43.81-47.84,43.81s-48-18.27-48-43.66V96.39z"/>
		<path fill="#7dbde8" d="M1114.02,94.69c31.58,0,55.12,21.98,55.12,53.41s-23.53,53.42-55.12,53.42c-31.58,0-55.27-21.99-55.27-53.42
			S1082.43,94.69,1114.02,94.69z M1114.02,118.22c-17.18,0-28.18,13-28.18,29.88c0,16.72,10.99,29.88,28.18,29.88
			c17.03,0,28.02-13.16,28.02-29.88C1142.04,131.23,1131.05,118.22,1114.02,118.22z"/>
		<path fill="#7dbde8" d="M1196.96,199.66v-80.04h-28.95V96.39h84.38v23.22h-28.8v80.04H1196.96z"/>
		<path fill="#FFFFFF" d="M1295.08,199.66v-41.65l-39.17-61.62h30.04l22.45,38.09l22.14-38.09h30.19l-39.02,61.62v41.65H1295.08z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M390.99,273.46v-32.52h4.05v32.52H390.99z"/>
		<path fill="#FFFFFF" d="M401.34,257.22c0-9.99,7.36-16.82,16.58-16.82c5.9,0,9.9,2.78,12.48,6.44l-3.41,1.9
			c-1.85-2.73-5.22-4.73-9.07-4.73c-6.97,0-12.39,5.46-12.39,13.21c0,7.7,5.41,13.21,12.39,13.21c3.85,0,7.22-1.95,9.07-4.73
			l3.46,1.9c-2.73,3.71-6.63,6.43-12.53,6.43C408.7,274.05,401.34,267.22,401.34,257.22z"/>
		<path fill="#FFFFFF" d="M443.68,273.46v-28.91H433.4v-3.61h24.67v3.61h-10.33v28.91H443.68z"/>
		<path fill="#FFFFFF" d="M491.45,240.4c5.75,0,9.75,2.54,12.63,6.1l-3.22,2c-2.05-2.63-5.51-4.49-9.41-4.49
			c-7.12,0-12.53,5.46-12.53,13.21c0,7.7,5.41,13.26,12.53,13.26c3.9,0,7.12-1.9,8.73-3.51v-6.63h-11.17v-3.61h15.21v11.75
			c-3.02,3.36-7.46,5.61-12.77,5.61c-9.27,0-16.73-6.78-16.73-16.87S482.19,240.4,491.45,240.4z"/>
		<path fill="#FFFFFF" d="M529.65,273.46l-8.29-12.92h-6.49v12.92h-4.05v-32.52h13.07c5.95,0,10.19,3.8,10.19,9.8
			c0,5.85-4,9.07-8.44,9.46l8.78,13.26H529.65z M523.41,244.55h-8.53v12.43h8.53c3.85,0,6.49-2.58,6.49-6.24
			C529.9,247.08,527.26,244.55,523.41,244.55z"/>
		<path fill="#FFFFFF" d="M555.32,240.4c9.61,0,16.14,7.22,16.14,16.82s-6.53,16.82-16.14,16.82c-9.66,0-16.14-7.22-16.14-16.82
			S545.67,240.4,555.32,240.4z M555.32,244.01c-7.31,0-11.95,5.61-11.95,13.21c0,7.56,4.63,13.21,11.95,13.21
			c7.26,0,11.94-5.66,11.94-13.21C567.27,249.62,562.59,244.01,555.32,244.01z"/>
		<path fill="#FFFFFF" d="M577.83,240.94h4.05v19.8c0,5.95,3.22,9.7,9.21,9.7c6,0,9.21-3.76,9.21-9.7v-19.8h4.05v19.85
			c0,8.09-4.44,13.26-13.26,13.26c-8.83,0-13.26-5.22-13.26-13.21V240.94z"/>
		<path fill="#FFFFFF" d="M611.99,273.46v-32.52h13.07c6.54,0,10.19,4.49,10.19,9.8c0,5.32-3.76,9.8-10.19,9.8h-9.02v12.92H611.99z
			 M624.57,244.55h-8.53v12.39h8.53c3.85,0,6.49-2.54,6.49-6.19C631.05,247.08,628.42,244.55,624.57,244.55z"/>
	</g>
	<g>
		<rect x="0" y="0" fill="#FFFFFF" width="314.05" height="314.05"/>
		<g>
			<g>
				<path fill="#0F596E" d="M39.38,270.19c-4.14,0-7.5-3.5-7.5-7.8v-36.73c0-4.3,3.36-7.8,7.5-7.8h138.84v52.33H39.38z"/>
				<path fill="#FFFFFF" d="M174.09,221.98v7.35v29.39v7.35H39.38c-1.86,0-3.37-1.64-3.37-3.67v-36.73c0-2.03,1.51-3.67,3.37-3.67
					H174.09 M182.34,213.73h-8.25H39.38c-6.41,0-11.62,5.35-11.62,11.92v36.73c0,6.57,5.21,11.92,11.62,11.92h134.71h8.25v-8.25
					v-7.35v-29.39v-7.35V213.73L182.34,213.73z"/>
			</g>
			<g>
				<path fill="#0F596E" d="M158.16,266.71c-67.08,0-121.66-54.57-121.66-121.66S91.08,23.4,158.16,23.4s121.66,54.57,121.66,121.66
					S225.24,266.71,158.16,266.71z M158.16,68.75c-42.07,0-76.3,34.23-76.3,76.3c0,42.07,34.23,76.3,76.3,76.3
					c42.07,0,76.3-34.23,76.3-76.3C234.46,102.98,200.23,68.75,158.16,68.75z"/>
				<path fill="#FFFFFF" d="M158.16,27.7c64.71,0,117.35,52.64,117.35,117.35c0,64.71-52.64,117.35-117.35,117.35
					S40.81,209.76,40.81,145.05C40.81,80.34,93.45,27.7,158.16,27.7 M158.16,225.66c44.45,0,80.61-36.15,80.61-80.6
					c0-44.45-36.16-80.61-80.61-80.61s-80.61,36.16-80.61,80.61C77.55,189.5,113.71,225.66,158.16,225.66 M158.16,19.09
					C88.7,19.09,32.2,75.6,32.2,145.05c0,69.45,56.51,125.96,125.96,125.96s125.96-56.51,125.96-125.96
					C284.12,75.6,227.61,19.09,158.16,19.09L158.16,19.09z M158.16,217.05c-39.7,0-72-32.3-72-72c0-39.7,32.3-72,72-72
					s72,32.3,72,72C230.16,184.75,197.86,217.05,158.16,217.05L158.16,217.05z"/>
			</g>
			<path fill="#0F596E" stroke="#FFFFFF" strokeWidth="8.338" strokeMiterlimit="10" d="M157.69,221.49h80.01v-11.33c0-1.35,0.69-2.59,1.81-3.24c1.11-0.64,2.47-0.58,3.53,0.17l48.38,33.94
				c0.94,0.66,1.52,1.77,1.55,2.98c0.03,1.2-0.5,2.34-1.4,3.06l-48.38,37.85c-0.6,0.47-1.32,0.71-2.04,0.71
				c-0.53,0-1.06-0.13-1.56-0.4c-1.16-0.63-1.89-1.89-1.89-3.28v-15.39h-80.01"/>
		</g>
	</g>
</g>
</svg>
)