import React from 'react';

const Recovery = () => {
	return (
		<>
			<h1>Recovery</h1>
		</>
	)
}

export default Recovery;