export interface SeverityItem {
	value: string;
	className: string
}

export interface AssetUpdateData {
	id: number;
	cve: string;
	cwe: string;
	description: string;
	mitigatingFactor: string;
	note: string;
	deleted: boolean;
	severity: SeverityItem[];
}

export const updatesTableData: AssetUpdateData[] = [
	{
		"id": 0,
		"cve": "cve-1-1",
		"cwe": "cwe-1-1",
		"description": "Description for asset 1-1 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-1",
		"note": "Note for asset 1-1",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 1,
		"cve": "cve-1-2",
		"cwe": "cwe-1-2",
		"description": "Description for asset 1-2 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-2",
		"note": "Note for asset 1-2",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 2,
		"cve": "cve-1-3",
		"cwe": "cwe-1-3",
		"description": "Description for asset 1-3 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-3",
		"note": "Note for asset 1-3",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 3,
		"cve": "cve-1-4",
		"cwe": "cwe-1-4",
		"description": "Description for asset 1-4 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-4",
		"note": "Note for asset 1-4",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 4,
		"cve": "cve-1-5",
		"cwe": "cwe-1-5",
		"description": "Description for asset 1-5 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-5",
		"note": "Note for asset 1-5",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 5,
		"cve": "cve-1-6",
		"cwe": "cwe-1-6",
		"description": "Description for asset 1-6 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-6",
		"note": "Note for asset 1-6",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 6,
		"cve": "cve-1-7",
		"cwe": "cwe-1-7",
		"description": "Description for asset 1-7 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-7",
		"note": "Note for asset 1-7",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 7,
		"cve": "cve-1-8",
		"cwe": "cwe-1-8",
		"description": "Description for asset 1-8 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-8",
		"note": "Note for asset 1-8",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 8,
		"cve": "cve-1-9",
		"cwe": "cwe-1-9",
		"description": "Description for asset 1-9 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-9",
		"note": "Note for asset 1-9",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 9,
		"cve": "cve-1-10",
		"cwe": "cwe-1-10",
		"description": "Description for asset 1-10 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-10",
		"note": "Note for asset 1-10",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 10,
		"cve": "cve-1-11",
		"cwe": "cwe-1-11",
		"description": "Description for asset 1-11 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-11",
		"note": "Note for asset 1-11",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 11,
		"cve": "cve-1-12",
		"cwe": "cwe-1-12",
		"description": "Description for asset 1-12 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-12",
		"note": "Note for asset 1-12",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 12,
		"cve": "cve-1-13",
		"cwe": "cwe-1-13",
		"description": "Description for asset 1-13 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-13",
		"note": "Note for asset 1-13",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 13,
		"cve": "cve-1-14",
		"cwe": "cwe-1-14",
		"description": "Description for asset 1-14 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-14",
		"note": "Note for asset 1-14",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 14,
		"cve": "cve-1-15",
		"cwe": "cwe-1-15",
		"description": "Description for asset 1-15 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-15",
		"note": "Note for asset 1-15",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 15,
		"cve": "cve-1-16",
		"cwe": "cwe-1-16",
		"description": "Description for asset 1-16 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-16",
		"note": "Note for asset 1-16",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 16,
		"cve": "cve-1-17",
		"cwe": "cwe-1-17",
		"description": "Description for asset 1-17 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-17",
		"note": "Note for asset 1-17",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 17,
		"cve": "cve-1-18",
		"cwe": "cwe-1-18",
		"description": "Description for asset 1-18 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-18",
		"note": "Note for asset 1-18",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 18,
		"cve": "cve-1-19",
		"cwe": "cwe-1-19",
		"description": "Description for asset 1-19 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-19",
		"note": "Note for asset 1-19",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 19,
		"cve": "cve-1-20",
		"cwe": "cwe-1-20",
		"description": "Description for asset 1-20 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-20",
		"note": "Note for asset 1-20",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 20,
		"cve": "cve-1-21",
		"cwe": "cwe-1-21",
		"description": "Description for asset 1-21 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-21",
		"note": "Note for asset 1-21",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 21,
		"cve": "cve-1-22",
		"cwe": "cwe-1-22",
		"description": "Description for asset 1-22 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-22",
		"note": "Note for asset 1-22",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 22,
		"cve": "cve-1-23",
		"cwe": "cwe-1-23",
		"description": "Description for asset 1-23 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-23",
		"note": "Note for asset 1-23",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 23,
		"cve": "cve-1-24",
		"cwe": "cwe-1-24",
		"description": "Description for asset 1-24 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-24",
		"note": "Note for asset 1-24",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 24,
		"cve": "cve-1-25",
		"cwe": "cwe-1-25",
		"description": "Description for asset 1-25 in production line",
		"mitigatingFactor": "Mitigating factor for asset 1-25",
		"note": "Note for asset 1-25",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	}
];
export const updatesTableDecidedData: AssetUpdateData[] = [
	{
		"id": 0,
		"cve": "cve-2-1",
		"cwe": "cwe-2-1",
		"description": "Description for asset 2-1 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-1",
		"note": "Note for asset 2-1",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 1,
		"cve": "cve-2-2",
		"cwe": "cwe-2-2",
		"description": "Description for asset 2-2 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-2",
		"note": "Note for asset 2-2",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 2,
		"cve": "cve-2-3",
		"cwe": "cwe-2-3",
		"description": "Description for asset 2-3 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-3",
		"note": "Note for asset 2-3",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 3,
		"cve": "cve-2-4",
		"cwe": "cwe-2-4",
		"description": "Description for asset 2-4 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-4",
		"note": "Note for asset 2-4",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 4,
		"cve": "cve-2-5",
		"cwe": "cwe-2-5",
		"description": "Description for asset 2-5 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-5",
		"note": "Note for asset 2-5",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 5,
		"cve": "cve-2-6",
		"cwe": "cwe-2-6",
		"description": "Description for asset 2-6 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-6",
		"note": "Note for asset 2-6",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 6,
		"cve": "cve-2-7",
		"cwe": "cwe-2-7",
		"description": "Description for asset 2-7 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-7",
		"note": "Note for asset 2-7",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 7,
		"cve": "cve-2-8",
		"cwe": "cwe-2-8",
		"description": "Description for asset 2-8 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-8",
		"note": "Note for asset 2-8",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 8,
		"cve": "cve-2-9",
		"cwe": "cwe-2-9",
		"description": "Description for asset 2-9 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-9",
		"note": "Note for asset 2-9",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 9,
		"cve": "cve-2-10",
		"cwe": "cwe-2-10",
		"description": "Description for asset 2-10 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-10",
		"note": "Note for asset 2-10",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 10,
		"cve": "cve-2-11",
		"cwe": "cwe-2-11",
		"description": "Description for asset 2-11 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-11",
		"note": "Note for asset 2-11",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 11,
		"cve": "cve-2-12",
		"cwe": "cwe-2-12",
		"description": "Description for asset 2-12 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-12",
		"note": "Note for asset 2-12",
		"deleted": true,
		"severity": [
			{
				"value": "normal",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 12,
		"cve": "cve-2-13",
		"cwe": "cwe-2-13",
		"description": "Description for asset 2-13 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-13",
		"note": "Note for asset 2-13",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 13,
		"cve": "cve-2-14",
		"cwe": "cwe-2-14",
		"description": "Description for asset 2-14 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-14",
		"note": "Note for asset 2-14",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 14,
		"cve": "cve-2-15",
		"cwe": "cwe-2-15",
		"description": "Description for asset 2-15 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-15",
		"note": "Note for asset 2-15",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 15,
		"cve": "cve-2-16",
		"cwe": "cwe-2-16",
		"description": "Description for asset 2-16 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-16",
		"note": "Note for asset 2-16",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 16,
		"cve": "cve-2-17",
		"cwe": "cwe-2-17",
		"description": "Description for asset 2-17 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-17",
		"note": "Note for asset 2-17",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 17,
		"cve": "cve-2-18",
		"cwe": "cwe-2-18",
		"description": "Description for asset 2-18 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-18",
		"note": "Note for asset 2-18",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 18,
		"cve": "cve-2-19",
		"cwe": "cwe-2-19",
		"description": "Description for asset 2-19 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-19",
		"note": "Note for asset 2-19",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-yellow"
			}
		]
	},
	{
		"id": 19,
		"cve": "cve-2-20",
		"cwe": "cwe-2-20",
		"description": "Description for asset 2-20 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-20",
		"note": "Note for asset 2-20",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 20,
		"cve": "cve-2-21",
		"cwe": "cwe-2-21",
		"description": "Description for asset 2-21 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-21",
		"note": "Note for asset 2-21",
		"deleted": false,
		"severity": [
			{
				"value": "normal",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 21,
		"cve": "cve-2-22",
		"cwe": "cwe-2-22",
		"description": "Description for asset 2-22 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-22",
		"note": "Note for asset 2-22",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	},
	{
		"id": 22,
		"cve": "cve-2-23",
		"cwe": "cwe-2-23",
		"description": "Description for asset 2-23 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-23",
		"note": "Note for asset 2-23",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 23,
		"cve": "cve-2-24",
		"cwe": "cwe-2-24",
		"description": "Description for asset 2-24 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-24",
		"note": "Note for asset 2-24",
		"deleted": true,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-purple"
			}
		]
	},
	{
		"id": 24,
		"cve": "cve-2-25",
		"cwe": "cwe-2-25",
		"description": "Description for asset 2-25 in production line",
		"mitigatingFactor": "Mitigating factor for asset 2-25",
		"note": "Note for asset 2-25",
		"deleted": false,
		"severity": [
			{
				"value": "Critical",
				"className": "bg-blue"
			}
		]
	}
];

const updatedData = updatesTableData.map(item => {
	const age2 = Math.floor(Math.random() * 100);
	const id2 = Math.floor(Math.random() * 10000);
	return {
	  ...item,
	  age2,
	  id2
	};
  });

export type Actions = 'solved' | 'required' | 'not taken';
export interface AssetUpdateHistoryData {
	action: Actions;
	severity: SeverityItem[];
	age: number;
	target: string;
	risk: string;
	id: string
}
