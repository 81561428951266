const softwareAssets: any  = {
  "data":  [
    {
      "id": 45,
      "moreInfo": "ENG",
      "function": "Engineering",
      "virtualOrPhysical": "Virtual",
      "runningOnPcName": "SRPAPP903068",
      "fcItemNr": "VersionDog Client 8.5",
      "installSoftwareAvailableOnSite": "",
      "slaOrSupportContract": "24/7",
      "manufacturerDocumentationAvailable": "",
      "remarks": "",
      "mainSystemPart": "Acelli Kokersnijder Motors",
      "budgetPlantPart": "Production",
      "location": {
        "id": 11,
        "installationName": "Sample Data",
        "installationNumber": "",
        "description": "",
        "locationType": null,
        "parentNode": null,
        "site": {
          "id": 19,
          "name": "Sample Site",
          "description": "Site 3 123",
          "address": null,
          "phone": null,
          "locations": [],
          "customer": {
            "id": 8,
            "name": "SampleClient 2",
            "description": "Final Description",
            "sites": [],
            "contactPersons": []
          }
        },
        "softwareAssets": [],
        "hardwareAssets": []
      },
      "server": null,
      "softwareComponent": {
        "id": 71,
        "description": "VersionDog Client 8.5",
        "manufacturer": {
          "id": 19,
          "name": "Auvesy",
          "description": null,
          "softwareComponents": []
        },
        "version": "V8.5",
        "moreInfo": "ENG",
        "productLifecycle": null,
        "activeProductUpgradeAvailable": "",
        "successorType": "",
        "remarks": "",
        "vilocifyComponent": null
      },
      "equipmentType": {
        "id": 7,
        "name": "DCS",
        "description": null
      }
    },
    {
      "id": 46,
      "moreInfo": "TPM",
      "function": "Inbatch server",
      "virtualOrPhysical": "Viartual",
      "runningOnPcName": "SRPAPP903057",
      "fcItemNr": "Batch Management 2020",
      "installSoftwareAvailableOnSite": "",
      "slaOrSupportContract": "24/7",
      "manufacturerDocumentationAvailable": "",
      "remarks": "",
      "mainSystemPart": "Acelli Kokersnijder Elec",
      "budgetPlantPart": "Production",
      "location": {
        "id": 11,
        "installationName": "Sample Data",
        "installationNumber": "",
        "description": "",
        "locationType": null,
        "parentNode": null,
        "site": {
          "id": 19,
          "name": "Sample Site",
          "description": "Site 3 123",
          "address": null,
          "phone": null,
          "locations": [],
          "customer": {
            "id": 8,
            "name": "SampleClient 2",
            "description": "Final Description",
            "sites": [],
            "contactPersons": []
          }
        },
        "softwareAssets": [],
        "hardwareAssets": []
      },
      "server": null,
      "softwareComponent": {
        "id": 61,
        "description": "Batch Management 2020",
        "manufacturer": {
          "id": 16,
          "name": "Aveva",
          "description": null,
          "softwareComponents": []
        },
        "version": "2020",
        "moreInfo": "TPM",
        "productLifecycle": null,
        "activeProductUpgradeAvailable": "",
        "successorType": "",
        "remarks": "",
        "vilocifyComponent": null
      },
      "equipmentType": {
        "id": 5,
        "name": "SCADA",
        "description": null
      }
    },
    {
      "id": 47,
      "moreInfo": "Scada",
      "function": "Terminal server",
      "virtualOrPhysical": "Virtual",
      "runningOnPcName": "SRPAPP903061",
      "fcItemNr": "InTouch 2020 R2 P1",
      "installSoftwareAvailableOnSite": "",
      "slaOrSupportContract": "24/7",
      "manufacturerDocumentationAvailable": "",
      "remarks": "",
      "mainSystemPart": "Acelli Kokersnijder HMI",
      "budgetPlantPart": "Production",
      "location": {
        "id": 11,
        "installationName": "Sample Data",
        "installationNumber": "",
        "description": "",
        "locationType": null,
        "parentNode": null,
        "site": {
          "id": 19,
          "name": "Sample Site",
          "description": "Site 3 123",
          "address": null,
          "phone": null,
          "locations": [],
          "customer": {
            "id": 8,
            "name": "SampleClient 2",
            "description": "Final Description",
            "sites": [],
            "contactPersons": []
          }
        },
        "softwareAssets": [],
        "hardwareAssets": []
      },
      "server": null,
      "softwareComponent": {
        "id": 63,
        "description": "InTouch 2020 R2 P1",
        "manufacturer": {
          "id": 16,
          "name": "Aveva",
          "description": null,
          "softwareComponents": []
        },
        "version": "2020 R2",
        "moreInfo": "Scada",
        "productLifecycle": null,
        "activeProductUpgradeAvailable": "",
        "successorType": "",
        "remarks": "",
        "vilocifyComponent": null
      },
      "equipmentType": {
        "id": 5,
        "name": "SCADA",
        "description": null
      }
    },
    {
      "id": 48,
      "moreInfo": "MES",
      "function": "DB Server",
      "virtualOrPhysical": "Virtual",
      "runningOnPcName": "SRPAPP903053",
      "fcItemNr": "SQL Server 2019 Standard",
      "installSoftwareAvailableOnSite": "",
      "slaOrSupportContract": "24/7",
      "manufacturerDocumentationAvailable": "",
      "remarks": "",
      "mainSystemPart": "Acelli Kokersnijder Elec",
      "budgetPlantPart": "Production",
      "location": {
        "id": 11,
        "installationName": "Sample Data",
        "installationNumber": "",
        "description": "",
        "locationType": null,
        "parentNode": null,
        "site": {
          "id": 19,
          "name": "Sample Site",
          "description": "Site 3 123",
          "address": null,
          "phone": null,
          "locations": [],
          "customer": {
            "id": 8,
            "name": "SampleClient 2",
            "description": "Final Description",
            "sites": [],
            "contactPersons": []
          }
        },
        "softwareAssets": [],
        "hardwareAssets": []
      },
      "server": null,
      "softwareComponent": {
        "id": 70,
        "description": "SQL Server 2019 Standard",
        "manufacturer": {
          "id": 18,
          "name": "Microsoft",
          "description": null,
          "softwareComponents": []
        },
        "version": "2019",
        "moreInfo": "MES",
        "productLifecycle": null,
        "activeProductUpgradeAvailable": "",
        "successorType": "",
        "remarks": "",
        "vilocifyComponent": null
      },
      "equipmentType": {
        "id": 6,
        "name": "MES / Local-WMS",
        "description": null
      }
    },
    {
      "id": 49,
      "moreInfo": "DMZ",
      "function": "WEI",
      "virtualOrPhysical": "Virtual",
      "runningOnPcName": "SRPAPP903012",
      "fcItemNr": "SQL Server 2019 Express",
      "installSoftwareAvailableOnSite": "",
      "slaOrSupportContract": "24/7",
      "manufacturerDocumentationAvailable": "",
      "remarks": "",
      "mainSystemPart": "STAMM1 PLC",
      "budgetPlantPart": "Production",
      "location": {
        "id": 11,
        "installationName": "Sample Data",
        "installationNumber": "",
        "description": "",
        "locationType": null,
        "parentNode": null,
        "site": {
          "id": 19,
          "name": "Sample Site",
          "description": "Site 3 123",
          "address": null,
          "phone": null,
          "locations": [],
          "customer": {
            "id": 8,
            "name": "SampleClient 2",
            "description": "Final Description",
            "sites": [],
            "contactPersons": []
          }
        },
        "softwareAssets": [],
        "hardwareAssets": []
      },
      "server": null,
      "softwareComponent": {
        "id": 69,
        "description": "SQL Server 2019 Express",
        "manufacturer": {
          "id": 18,
          "name": "Microsoft",
          "description": null,
          "softwareComponents": []
        },
        "version": "2019",
        "moreInfo": "DMZ",
        "productLifecycle": null,
        "activeProductUpgradeAvailable": "",
        "successorType": "",
        "remarks": "",
        "vilocifyComponent": null
      },
      "equipmentType": {
        "id": 6,
        "name": "MES / Local-WMS",
        "description": null
      }
    }
  ],
  "count": 5,
  "success": true,
  "message": "Successfully retreived 5 softwareAssets."
}

export default softwareAssets;