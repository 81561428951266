import config from "@/config";

export const auth = {
	getApiKey: async () => {
		const apiKey = await fetch( `${ config.authApiUrl }/Security/GetApiKey?userKey=user&applicationKey=app`, {
			headers: {
				StorageId: config.storageId
			}
		} )
		const apiKeyJson = await apiKey.json();
		const result = apiKeyJson?.data?.apiKey as number;

		return result.toString();
	}
};

export default auth;
