import { IFilter } from "@/pages/dashboard-outlet/Inventory";

export const usageFilters:IFilter[] = [
	{
		category: 'usage',
		filter: 'In use',
	},
	{
		category: 'usage',
		filter: 'In stock',
	},
	{
		category: 'usage',
		filter: 'No stock',
	},
];

export const MSPs:IFilter[] = [
	{
		category: 'msp',
		filter: 'MSP - PLC CPU',
	},
	{
		category: 'msp',
		filter: 'MSP - PLC Other',
	},
	{
		category: 'msp',
		filter: 'MSP - HMI panel',
	},
	{
		category: 'msp',
		filter: 'MSP - Network',
	},
	{
		category: 'msp',
		filter: 'MSP - Scada server',
	}
]