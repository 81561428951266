import React from 'react';

const Benchmark = () => {
	return (
		<>
			<h1>Benchmark</h1>
		</>
	)
}

export default Benchmark;