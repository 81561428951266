import React from 'react';
import { ChevronRight } from "lucide-react";
import { UserNav } from '@/components/shadcn-ui/user-nav';
import { Logo } from '@/components/Logo';
import MainNav from '@/components/ui/MainNav';

const DashboardHeader = () => {
	return (
		<>
			<div className="flex flex-row items-end justify-between space-y-0  bg-dark-blue px-4">
				<div className="w-56">
					<div className="pt-2 pb-8">
						<Logo className="w-24 h-auto" />
					</div>
				</div>
				<MainNav />
				<div className="ml-auto flex items-center space-x-4 py-4">
					<UserNav />
				</div>
			</div>
		</>
	);
}

export default DashboardHeader;