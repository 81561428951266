import { useState, useEffect, useCallback } from 'react';
import config from '@/config';
import { Card, CardContent } from '@/components/shadcn-ui/card';
import FilterItem from '@/components/ui/FilterItem';
import Filter from '@/components/ui/Filter';
import InventoryTable from '@/components/InventoryTable';
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import { useRecoilValue } from 'recoil';
import { selectedPathAtom } from '@/state/selected-path';
import api from '@/middleware/api';
import {
	HardwareAsset,
	SoftwareAsset,
	EquipmentTypeIEnumerableWebResponse,
} from '@/middleware/GeneratedClient';
import { usageFilters, MSPs } from '@/mocks/data/inventoryFilters';
import hardwareAssets from '@/mocks/data/hardwareAssets';
import softwareAssets from '@/mocks/data/softwareAssets';
import CustomTabs from '@/components/ui/CustomTabs';
import { Input } from '@/components/shadcn-ui/input';
import { Icons } from '@/components/shadcn-ui/icons';

type Category =
	| 'deviceType'
	| 'manufacturer'
	| 'usage'
	| 'lifecycleStatus'
	| 'msp'
	| 'equipmentType';

interface Filters {
	deviceTypes: IFilter[];
	manufacturers: IFilter[];
	usage: IFilter[];
	lifecycleStatus: IFilter[];
	msp: IFilter[];
	equipmentType: IFilter[];
}

export interface IFilter {
	category: Category;
	filter: string;
}

const filterCategories = {
	deviceType: 'Device Type',
	manufacturer: 'Manufacturer',
	usage: 'Usage',
	lifecycleStatus: 'Lifecycle status',
	msp: 'MSP',
	equipmentType: 'Equipment Type',
};

const Inventory = () => {
	const [hwAssets, setHwAssets] = useState<HardwareAsset[]>([]);
	const [swAssets, setSwAssets] = useState<SoftwareAsset[]>([]);
	const [filters, setFilters] = useState<Filters>({
		deviceTypes: [],
		manufacturers: [],
		usage: usageFilters,
		lifecycleStatus: [],
		msp: MSPs,
		equipmentType: [],
	});
	const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);
	const [filteredAssets, setFilteredAssets] = useState<HardwareAsset[]>(hwAssets);

	const [currentTab, setCurrentTab] = useState('Hardware');

	const selectedPath = useRecoilValue(selectedPathAtom);

	useEffect(() => {
		(async () => {
			try {
				const customerNode = selectedPath.find((item) =>
					item.id.toString().includes('customer_')
				);
				const siteNode = selectedPath.find((item) =>
					item.id.toString().includes('site_')
				);
				const locationNode = selectedPath.find((item) =>
					item.id.toString().includes('location_')
				);

				if (customerNode && locationNode && siteNode) {
					//const customerId = customerNode.id.toString().split('customer_')[1];
					//const siteId = siteNode.id.toString().split('site_')[1];
					//const locationId = locationNode.id.toString().split('location_')[1];
					/*const hwAssets = await api.hardwareAssets(
						+customerId,
						+siteId,
						+locationId
					);*/

					/*const hwAssets = hardwareAssets(
						+customerId,
						+siteId,
						+locationId
					);*/

					const hwAssets = hardwareAssets;

					if (
						hwAssets &&
						hwAssets.data !== null &&
						hwAssets.data !== undefined
					) {
						setHwAssets(hwAssets.data);
						setFilteredAssets(hwAssets.data);
					}
					/*const swAssets = await api.softwareAssets(
						+customerId,
						+siteId,
						+locationId
					);*/

					/*const swAssets = softwareAssets(
						+customerId,
						+siteId,
						+locationId
					);*/
					const swAssets = softwareAssets;
					if (
						swAssets &&
						swAssets.data !== null &&
						swAssets.data !== undefined
					) {
						setSwAssets(swAssets.data);
					}
				}
			} catch {
				//setHwAssets([]);
				//setFilteredAssets([]);
				//setSwAssets([]);
			}
		})();
	}, [selectedPath]);

	const filterAssets = useCallback(
		(selectedFilters: IFilter[]) => {
			let deviceTypeFilters = selectedFilters.filter(
				(item) => item.category === 'deviceType'
			);
			let manufacturerFilters = selectedFilters.filter(
				(item) => item.category === 'manufacturer'
			);

			let firstRoundFilter: HardwareAsset[] = [];
			let secondRoundFilter: HardwareAsset[] = [];
			let finalAssets: HardwareAsset[] = [];
			let toFilter: HardwareAsset[] = [];

			firstRoundFilter = hwAssets.filter((asset) => {
				let result = false;
				for (let i = 0; i < deviceTypeFilters.length; i++) {
					if (
						deviceTypeFilters[i].filter ===
						asset.hardwareComponent?.deviceType?.name
					) {
						result = true;
						break;
					}
				}
				return result;
			});
			toFilter = firstRoundFilter.length ? firstRoundFilter : hwAssets;
			secondRoundFilter = toFilter.filter((asset) => {
				let result = false;
				for (let i = 0; i < manufacturerFilters.length; i++) {
					if (
						manufacturerFilters[i].filter ===
						asset.hardwareComponent?.manufacturer?.name
					) {
						result = true;
						break;
					}
				}
				return result;
			});

			finalAssets = secondRoundFilter.length
				? secondRoundFilter
				: firstRoundFilter.length
				? firstRoundFilter
				: hwAssets;

			return finalAssets;
		},
		[hwAssets]
	);

	const handleSelect = useCallback(
		(item: IFilter) => {
			const foundIndex = selectedFilters.findIndex(
				(selFil) => selFil.filter === item.filter
			);
			const updatedSelectedFilters =
				foundIndex > -1
					? [
							...selectedFilters.slice(0, foundIndex),
							...selectedFilters.slice(foundIndex + 1),
					  ]
					: [...selectedFilters, item];

			// Filter assets including the newly added/removed filter
			const finalAssets = filterAssets(updatedSelectedFilters);
			setFilteredAssets(finalAssets);

			if (foundIndex > -1) {
				setSelectedFilters((prevState) => {
					const left = prevState.slice(0, foundIndex);
					const right = prevState.slice(foundIndex + 1);
					return [...left, ...right];
				});
			} else {
				setSelectedFilters((prevState) => [...prevState, item]);
			}
		},
		[setSelectedFilters, selectedFilters, filterAssets]
	);

	const getFilters = useCallback(
		async (
			category: Category,
			apiMethod: Promise<EquipmentTypeIEnumerableWebResponse>
		) => {
			let filterSet: Set<string> = new Set();
			const filterData = await apiMethod;
			const filterObjects = filterData.data;

			if (filterObjects) {
				filterObjects.forEach((filterObject) => {
					if (
						filterObject.name &&
						filterObject.name !== null &&
						filterObject.name !== ''
					)
						filterSet.add(filterObject.name);
				});
			}

			const categoryFilters: IFilter[] = Array.from(filterSet)
				.sort()
				.map((filter) => {
					return { category: category, filter: filter };
				});
			return categoryFilters;
		},
		[]
	);

	useEffect(() => {
		let manufacturers = new Set<string>();
		async function getAllFilterItems() {
			try {
				const deviceTypeFilters = await getFilters(
					'deviceType',
					api.deviceTypes()
				);
				const lifecycleFilters = await getFilters(
					'lifecycleStatus',
					api.lifecycleStatuses()
				);
				const equipmentTypeFilters = await getFilters(
					'equipmentType',
					api.equipmentTypes()
				);

				//Manufacturers
				hwAssets.forEach((asset) => {
					if (asset.hardwareComponent) {
						manufacturers.add(
							asset.hardwareComponent.manufacturer?.name ||
								`manufacturer ${asset.hardwareComponent.manufacturer?.id}`
						);
					}
				});
				const manufacturerFilters: IFilter[] = Array.from(manufacturers)
					.sort()
					.map((filter) => {
						return { category: 'manufacturer', filter: filter };
					});

				setFilters((prevState) => ({
					deviceTypes: deviceTypeFilters,
					manufacturers: manufacturerFilters,
					usage: prevState.usage,
					lifecycleStatus: lifecycleFilters,
					msp: prevState.msp,
					equipmentType: equipmentTypeFilters,
				}));
			} catch {
				setFilters((prevState) => ({
					deviceTypes: [],
					manufacturers: [],
					usage: prevState.usage,
					lifecycleStatus: [],
					msp: prevState.msp,
					equipmentType: [],
				}));
			}
		}
		getAllFilterItems();
	}, [hwAssets, getFilters]);

	useEffect(() => {
		config.debug && console.log('filters', filters);
		//console.log('swAssets-=-=-=',swAssets)
	}, [filters]);

	const selectedPathTitle = useSelectedPathText();

	function handleSelectedTab(selectedTab: string) {
		setCurrentTab(selectedTab);
	}

	const filtersContent =
		currentTab === 'Hardware' ? (
			<Card className="overflow-hidden mt-[3.8rem] min-w-[11.5rem] w-[11.5rem] max-w-fit">
				<CardContent className="pl-2 text-sm space-y-6 overflow-y-auto max-h-[25rem]">
					{hwAssets.length ? (
						<>
							<Filter
								category={filterCategories.deviceType}
								items={filters.deviceTypes}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.manufacturer}
								items={filters.manufacturers}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.lifecycleStatus}
								items={filters.lifecycleStatus}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.usage}
								items={filters.usage}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.msp}
								items={filters.msp}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
						</>
					) : (
						<div className="p-4 py-8">No data to filter.</div>
					)}
				</CardContent>
			</Card>
		) : (
			<Card className="overflow-hidden mt-[3.8rem] min-w-[11.5rem] w-[11.5rem] max-w-fit">
				<CardContent className="pl-2 text-sm space-y-6 overflow-y-auto max-h-[25rem]">
					{swAssets.length ? (
						<>
							<Filter
								category={filterCategories.equipmentType}
								items={filters.equipmentType}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.manufacturer}
								items={filters.manufacturers}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.lifecycleStatus}
								items={filters.lifecycleStatus}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.msp}
								items={filters.msp}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
						</>
					) : (
						<div className="p-4 py-8">No data to filter.</div>
					)}
				</CardContent>
			</Card>
		);

	const tableContent =
		currentTab === 'Hardware' ? (
			<InventoryTable
				data={filteredAssets}
				className={`${selectedFilters.length ? 'mt-[3.9rem]' : 'mt-[.9rem]'}`}
				type='hardware'
			/>
		) : (
			<InventoryTable
				data={swAssets}
				className={`${selectedFilters.length ? 'mt-[3.9rem]' : 'mt-[.9rem]'}`}
				type='software'
			/>
		);

	return (
		<>
			<div className="flex flex-1 flex-col py-4 scroll-m-20 text-2xl tracking-tight">
				<h1 className="text-blue capitalize">{selectedPathTitle}</h1>
				<div className="flex flex-1 flex-col row-gap-0 col-gap-4 mt-8">
					<CustomTabs
						tabsOptions={[
							{
								option: 'Hardware',
								icon: <Icons.Hardware className="mr-2 h-4 w-4" />,
							},
							{
								option: 'Software',
								icon: <Icons.Software className="mr-2 h-4 w-4" />,
							},
						]}
						selectedTab={(selectedTab) => {
							handleSelectedTab(selectedTab);
						}}
					>
						<div className="flex flex-row relative">
							<div className="rounded-l-lg border border-t-0 border-r-0 bg-card text-card-foreground shadow-sm pl-3">
								<h3 className="space-y-1.5 p-6 pb-3 text-lg font-semibold leading-none tracking-tight text-dark-blue">
									Filters
								</h3>

								<div className="flex-grow p-y-2 max-w-fit">
									<div className="flex items-center my-2 absolute z-10">
										<Input
											id="AssetSearchTool"
											className="w-[11.5rem] max-w-fit mt-1.5 mr-5"
											placeholder="search here..."
											autoCapitalize="none"
											autoCorrect="off"
										/>
										{selectedFilters.map((item, index) => (
											<FilterItem
												key={`${index}-${item}`}
												item={item}
												handleRemove={handleSelect}
											/>
										))}
									</div>
								</div>
								{filtersContent}
							</div>
							{tableContent}
						</div>
					</CustomTabs>
				</div>
			</div>
		</>
	);
};

export default Inventory;
