import { Outlet } from 'react-router-dom';
import DashboardHeader from '@/components/DashboardHeader';
import { LeftSidebar } from '@/components/LeftSidebar';

const DashboardRoot = () => {
	return (
		<div className="bg-grey h-screen flex flex-col overflow-hidden">
			<DashboardHeader />
			<div className="flex flex-auto flex-row min-h-0 px-4">
				<div className="py-4 scroll-m-20 text-2xl tracking-tight h-full w-56">
					<LeftSidebar />
				</div>
				<div className="flex flex-1 bg-slate-200 p-4 h-full overflow-hidden">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default DashboardRoot;
