import React from 'react';
import {
	Card,
	CardHeader,
	CardTitle,
	CardContent,
} from '@/components/shadcn-ui/card';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/DataTable';
import { HardwareAsset, SoftwareAsset } from '@/middleware/GeneratedClient';

const columnsHW: ColumnDef<HardwareAsset>[] = [
	{
		accessorKey: 'hardwareComponent.deviceDescription',
		header: 'Component description',
	},
	{
		accessorKey: 'hardwareComponent.manufacturer.name',
		header: 'Manufacturer',
	},
	{
		accessorKey: 'hardwareComponent.typeNumber',
		header: 'Type number',
	},
	{
		accessorKey: 'hardwareComponent.deviceType.name',
		header: 'Device type',
	},
	{
		accessorKey: 'hardwareComponent.productLifecycle.name',
		header: 'Lifecycle status',
	},
	{
		accessorKey: 'hardwareComponent.endLifecyle.name',
		header: 'End lifecycle',
	},
	{
		accessorKey: 'inUseOrSpare',
		header: 'Usage',
	},
	{
		accessorKey: 'mainSystemPart',
		header: 'MSP',
	},
];

const columnsSW: ColumnDef<SoftwareAsset>[] = [
	{
		accessorKey: 'softwareComponent.description',
		header: 'Component description',
	},
	{
		accessorKey: 'softwareComponent.manufacturer.name',
		header: 'Manufacturer',
	},
	{
		accessorKey: 'softwareComponent.version',
		header: 'Version',
	},
	{
		accessorKey: 'equipmentType.name',
		header: 'Equipment type',
	},
	{
		accessorKey: 'server.operatingSystem',
		header: 'Operating system',
	},	
	{
		accessorKey: 'runningOnPcName',
		header: 'Running On PC Name',
	},
	{
		accessorKey: 'softwareComponent.productLifecycle.name',
		header: 'Lifecycle status',
	},
	{
		accessorKey: 'mainSystemPart',
		header: 'MSP',
	},
];

interface InventoryTableProps {
	data: HardwareAsset[]|SoftwareAsset[]
	className?: string;
	type: string;
}

const InventoryTable = ({data, className, type} : InventoryTableProps ) => {
	return (
		<Card className="flex flex-col flex-grow overflow-hidden max-h-[35rem] rounded-l-[0px] border-t-0 border-l-0 relative">
			<CardHeader className="pb-3">
				<CardTitle className="text-dark-blue">Assets</CardTitle>
			</CardHeader>
			<CardContent className={`flex flex-col flex-1 p-6 pr-3 pt-0 text-sm overflow-hidden ${className}`}>
				<DataTable columns={type === 'hardware' ? columnsHW : columnsSW} data={data} />
			</CardContent>
		</Card>
	);
};

export default InventoryTable;