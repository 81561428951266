import React from 'react';
import {
	Card,
	CardContent,
} from '@/components/shadcn-ui/card';
import SingleSelect from '@/components/ui/SingleSelect';
import { Icons } from '@/components/shadcn-ui/icons';
// import { cn } from '@/lib/utils';

const statusItems = ['Undecided', 'Decided'];

interface AssetDetails {
	Vendor: string;
	"Publish date": string;
	"Last date": string;
	action: string;
	impact: string;
	Description: string;
	Solution: string;
  }


const asset_details: AssetDetails = {
    "Vendor": "Siemens",
    "Publish date": "Jan 10, 2023, 14:23 UTC",
    "Last date": "Jan 10, 2023, 17:55 UTC",
    "action": "Follow Recommendation",
    "impact": "Manipulation of Data",
    "Description": "Refers to a critical security vulnerability affecting a range of Siemens products. This flaw allows unauthorized remote users to bypass authentication mechanisms, potentially gaining unauthorized access to sensitive systems and data.",
    "Solution": "Released a security patch to address the \"Remote Improper Authorization Vulnerability\" affecting multiple products, identified as SSA-865327. We strongly recommend that all affected users update their systems to the latest firmware version to mitigate unauthorized access risks."
};

const AssetDetails = () => {
	return (
		<Card>
			<CardContent className="p-6 text-sm">
				<h4 className="mb-2 font-semibold leading-none tracking-tight text-content-grey/90">Vulnerability details</h4>
				<h2 className="text-lg font-semibold leading-none">Siemens Multiple Products - Remote improper Authorization Vulnerability - SSA-865327</h2>
				<div className="flex mt-10 justify-between">
					<SingleSelect
						label='Action status'
						value={statusItems[0]}
						items={statusItems} />
					<div className='mx-auto bg-blue/80 rounded text-xs tracking-wide text-white hover:bg-grey hover:text-blue focus:bg-white focus:text-blue px-2 py-1'>
						<a href="#" target="_blank" className='flex items-center' >
							Show affected assets in new tab<Icons.ExternalLink className="ml-2 h-3 w-3" />
						</a>
					</div>
					<div className='underline decoration-sky-500/30 text-blue text-strong  border-gray-300 focus:border-blue-400 target:shadow-lg'>
						<a href="#" target="_blank" >Vilocify #888888</a>
					</div>
				</div>
				<div className='min-h-0'>
					<Card className='mt-2 border-0'>
						<CardContent className='p-0 text-sm flex'>
						<div className='flex-auto w-1/3'>
							{Object.keys(asset_details).map((key, index) => {
							const typedKey = key as keyof AssetDetails; // Type casting
							if (typedKey !== "Description" && typedKey !== "Solution") {
								return (
								<div key={key + index} className='flex items-center'>
									<span className='text-content-grey/90 flex-1'>{key}</span>
									<span className={`pl-3 font-${index === 0 ? 'semibold my-2' : 'normal' } flex-auto w-1/3`}>{asset_details[typedKey]}</span>
								</div>
								);
							}
							return null;
							})}
						</div>
						<div className='mt-2 flex-auto w-2/3'>
							<div className='flex justify-between'>
								<span className='text-content-grey/90'>Description</span>
								<span className='w-5/6 ml-3 p-3 font-normal border rounded'>{asset_details.Description}</span>
							</div>
							<div className='flex justify-between mt-2'>
								<span className='text-content-grey/90'>Solution</span>
								<span className='w-5/6 ml-3 p-3 font-normal border rounded'>{asset_details.Solution}</span>
							</div>
							</div>
						</CardContent>
					</Card>
					</div>
			</CardContent>
		</Card>
	)
}

export default AssetDetails;