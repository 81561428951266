import api from '@/middleware/api';
import { INode } from 'react-accessible-treeview';
import config from '@/config';

const buildLocationTree = async (customerId: number, siteId: number) => {
	try {
		const locations = await api.locations(customerId, siteId);
		const treeNodes: INode[] = [];

		if (locations.data) {
			for (let location of locations.data) {
				if (location && location.id) {
					const currentLocationNode: INode = {
						name: location.installationName ?? 'Unknown Customer',
						id: `location_${location.id}`,
						children: [],
						parent: `site_${siteId}`,
						isBranch: false,
					};

					treeNodes.push(currentLocationNode);
				}
			}
		}

		return {
			tree: treeNodes,
			directChildrenIds: treeNodes.map((item) => item.id),
		};
	} catch {
		return {
			tree: [],
			directChildrenIds: [],
		};
	}
};

const buildSiteTree = async (customerId: number) => {
	const customerSites = await api.sites(customerId);
	const treeNodes: INode[] = [];
	const sitesNodesIds: (string | number)[] = [];

	if (customerSites.data) {
		for (let site of customerSites.data) {
			console.log({ site })
			if (site && site.id) {
				const currentSiteNode: INode = {
					name: site.name ?? 'Unknown Customer',
					id: `site_${site.id}`,
					children: [],
					parent: `customer_${customerId}`,
					isBranch: true,
				};

				const subTreeData = await buildLocationTree(customerId, site.id);

				// Add current node
				treeNodes.push(currentSiteNode);
				sitesNodesIds.push(currentSiteNode.id);

				// Add subtree
				currentSiteNode.children = subTreeData.directChildrenIds;
				treeNodes.push(...subTreeData.tree);

				if (subTreeData.directChildrenIds.length === 0) {
					currentSiteNode.isBranch = false;
				}
			}
		}
	}

	return {
		tree: treeNodes,
		directChildrenIds: sitesNodesIds,
	};
};

export const buildCustomersTree = async () => {
	const actualRoot: INode = {
		name: '',
		id: 0,
		children: [1],
		parent: null,
	};

	const companyRoot: INode = {
		name: config.companyName,
		id: 1,
		children: [],
		parent: 0,
		isBranch: true,
	};

	const treeData = [actualRoot, companyRoot];
	try {
		let customers = await api.customers();
		config.debug && console.log({ customers });

		if (customers.data) {
			for (let customer of customers.data) {
				if (customer && customer.id) {
					const currentCustomerNode: INode = {
						name: customer.name ?? 'Unknown Customer',
						id: `customer_${customer.id}`,
						children: [],
						parent: 1,
						isBranch: true,
					};
					const subTreeData = await buildSiteTree(customer.id);
					const subTreeIds = subTreeData.directChildrenIds;

					// Add current item
					companyRoot.children.push(currentCustomerNode.id);
					treeData.push(currentCustomerNode);

					// Add the current item sub-tree
					currentCustomerNode.children = subTreeIds;
					treeData.push(...subTreeData.tree);
				}
			}
		}
	} catch (e) {
		console.warn({ e });
	}

	config.debug && console.log({ treeData });

	return treeData;
};

const findNodeById = (id: string | number, tree: INode[]) => {
	return tree.find((item) => item.id === id);
};

export const getPathToNode = (node: INode, tree: INode[]) => {
	let currentParentId = node.parent;
	let currentPrettyPath = [node];

	while (currentParentId !== null) {
		let currentParentNode = findNodeById(currentParentId, tree);

		if (!currentParentNode) {
			break;
		}

		currentPrettyPath.push(currentParentNode);
		currentParentId = currentParentNode.parent;
	}

	console.log({ currentPrettyPath });

	return currentPrettyPath.reverse();
};
