import React from 'react';
import {
	Chart as ChartJS,
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
	ChartOptions,
	Plugin,
	ChartData
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { RisksBubbleDataPoint } from '@/mocks/data/risksAnalysis';


ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options:ChartOptions<'bubble'> = {
	onClick: (event, elements, chart) => {
		if ( event.type === 'click' || event.type === 'dblclick' ) {
			if ( elements[0] ) {
				const activeElementIndex = elements[0].index;
				console.log('active element', chart.data.datasets[0].data[activeElementIndex])
			}
		}

	},
	scales: {
		x: {
			beginAtZero: false,
			position: 'top',
			ticks: {
				callback(tickValue, index, ticks) {
					let xLabel:string|null = null;
					switch (tickValue) {
						case 1: xLabel = 'Likely'; break;
						case 2: xLabel = 'Possible'; break;
						case 3: xLabel = 'Unlikely'; break;
						case 4: xLabel = 'Rare'; break;
					}
					return xLabel;
				},
				padding: 16
			},
			grid: {
				tickLength: 16
			},
			border: {
				display: false
			}
		},
		y: {
			beginAtZero: true,
			ticks: {
				callback(tickValue, index, ticks) {
					let yLabel:string|null = null;
					switch (tickValue) {
						case 1: yLabel = 'Negligible'; break;
						case 2: yLabel = 'Minor'; break;
						case 3: yLabel = 'Moderate'; break;
						case 4: yLabel = 'Major'; break;
						case 5: yLabel = 'Catastrophic'; break;
					}
					return yLabel;
				},
				padding: 16,
			},
			grid: {
				// drawTicks: false
				tickLength: 16
			},
			border: {
				display: false
			}
		},
		
	},
	plugins: {
		legend: {
			display: false
		}	
	},
	elements: {

		point: {
			radius(ctx, options) {
				// console.log('ctx.dataset.data', ctx.dataset.data);
				const pointData = ctx.dataset.data[ctx.dataIndex] as RisksBubbleDataPoint;
				// if ( pointData ) {
				// 	const size = ctx.chart.width;
				// 	const base = Math.abs(pointData.r) / 100;
				// 	return (size / 24) * base;
				// }

				if ( pointData ) {
					const score = 5 * (pointData.r);
					const incidentCount = pointData.msp.length;
					const diameter = ( score * incidentCount );
					console.log(`${pointData.yTickLabel} & ${pointData.xTickLabel}`)
					console.log( 'diameter', diameter );
					return diameter;
				}
			},
			backgroundColor(ctx, options) {
				const pointRawData = ctx.dataset.data[ctx.dataIndex] as RisksBubbleDataPoint;
				if ( pointRawData ) {
					const score = 5 * pointRawData.r;
					return `rgba(255, 54, 36, ${score/100})`;
				}
			},
		}
	}
};

const plugin:Plugin<'bubble'> = {
	id: 'riskGraph',
	afterDatasetsDraw: (chart) => {
		const ctx = chart.ctx;
		chart.data.datasets.forEach((dataset, i) => {
		var meta = chart.getDatasetMeta(i);
		if (!meta.hidden) {
		  meta.data.forEach((element, index) => {
			ctx.fillStyle = "#000";
			// console.log('dataset', dataset.data[index]);
			const currentBubble = dataset.data[index] as RisksBubbleDataPoint;
			const dataString = currentBubble.r.toString();
			ctx.font = "bold 16px arial";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			var position = element.tooltipPosition(true);
			ctx.fillText(dataString, position.x, position.y);
		  });
		}
	  });
	}
  };


interface RiskAnalysisChartProps {
	chartData: RisksBubbleDataPoint[]
}

const BubbleChart:React.FC<RiskAnalysisChartProps> = ({
	chartData
}) => {
	const data:ChartData<'bubble', RisksBubbleDataPoint[]> = {
		datasets: [
		  {
			data: chartData
		  },
		],
	}

  return <Bubble options={options} data={data} plugins={[plugin]} />;
}

export default BubbleChart;