import { RecoilRoot } from 'recoil';
import { RouterProvider, createHashRouter } from 'react-router-dom';

import Login from '@/pages/Login';
import DashboardRoot from '@/pages/DashboardRoot';
import Register from '@/pages/Register';
import Terms from '@/pages/Terms';
import Privacy from '@/pages/Privacy';

import Vulnerabilities from '@/pages/dashboard-outlet/Vulnerabilities';
import Inventory from '@/pages/dashboard-outlet/Inventory';
import Risks from '@/pages/dashboard-outlet/Risks';
import Benchmark from '@/pages/dashboard-outlet/Benchmark';
import OTCommunity from '@/pages/dashboard-outlet/OTCommunity';
import Recovery from '@/pages/dashboard-outlet/Recovery';
import ICTExpertise from '@/pages/dashboard-outlet/ICTExpertise';

const router = createHashRouter([
	{
		path: '/',
		index: true,
		element: <Login />,
		errorElement: <Login />,
	},
	{
		path: '/dashboard',
		element: <DashboardRoot />,
		children: [
			{
				path: 'vulnerabilities',
				element: <Vulnerabilities />,
				index: true,
			},
			{
				path: 'assets',
				element: <Inventory />,
			},
			{
				path: 'risks',
				element: <Risks />,
			},
			{
				path: 'benchmark',
				element: <Benchmark />,
			},
			{
				path: 'ot-community',
				element: <OTCommunity />,
			},
			{
				path: 'recovery',
				element: <Recovery />,
			},
			{
				path: 'ict-expertise',
				element: <ICTExpertise />,
			},
		],
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/terms-of-service',
		element: <Terms />,
	},
	{
		path: '/privacy-policy',
		element: <Privacy />,
	},
]);

function App() {
	return (
		<div className="h-screen bg-background font-sans antialiased">
			<RecoilRoot>
				<RouterProvider router={router} />
			</RecoilRoot>
		</div>
	);
}

export default App;
