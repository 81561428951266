import { useEffect, useState } from 'react';
import { buildCustomersTree } from '@/lib/tree-utils';
import { INode } from 'react-accessible-treeview';
import { Icons } from './shadcn-ui/icons';
import { Tree } from './ui/Tree';

export function LeftSidebar() {
	const [treeData, setTreeData] = useState<INode[]>([]);

	useEffect(() => {
		(async () => {
			const newTree = await buildCustomersTree();

			setTreeData(newTree);
		})();
	}, []);

	return (
		<>
			{treeData.length ? (
				<Tree data={treeData} />
			) : (
				<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
			)}
		</>
	);
}
