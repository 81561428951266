import config from '@/config';
import { Client } from './GeneratedClient'
import auth from './auth'

const API_KEY_STORAGE_NAME = 'bcpApiKey';

let getApiKey = async () => {
	const apiKey = await auth.getApiKey();
	// save key
	localStorage.setItem( API_KEY_STORAGE_NAME, apiKey );
    return apiKey;
};

let globalFetch = fetch;

const client = new Client( config.clientApiUrl, {
	fetch: async ( url, init ) => {
		// get key
		let apiKey = localStorage.getItem( API_KEY_STORAGE_NAME ) ? 
			localStorage.getItem( API_KEY_STORAGE_NAME ) : null;
		
		// no key in storage
		if ( !apiKey ) {
			console.log( 'no key in the storage. fetching...' );
			apiKey = await getApiKey();
			localStorage.setItem( API_KEY_STORAGE_NAME, apiKey );
		}

		let extendedInit = {
			...init,
			headers: {
				...( init?.headers || {} ),
				StorageId: config.storageId,
				ApplicationId: config.applicationId,
				UserId: config.userId,
				ApiKey: apiKey,
			}
		};
 
		console.log( 'Before test request' );
		let response = await globalFetch( url, extendedInit );
		console.log('After test request');

		//expired apiKey
		if ( response.statusText === 'Unauthorized' ) {
			console.log( 'expired api key. fetching...' );
			apiKey = await getApiKey();
		}

		extendedInit = {
			...extendedInit,
			headers: {
				...extendedInit.headers,
				ApiKey: apiKey
			}
		};

		return globalFetch( url, extendedInit );
	},
})

export const api = client;

export default api;
