import * as React from "react"
 
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/shadcn-ui/select"
import { Label } from "../shadcn-ui/label"
 
interface SingleSelectProps {
	label: string;
	items: string[];
	value: string;
}

const SingleSelect = ( { label, items, value } : SingleSelectProps ) => {
	return (
		<div className="flex items-center">
			<Label htmlFor="status" className="text-content-grey/90">
				{label}
			</Label>
			<Select>
				<SelectTrigger id="status" className="ml-3 w-[10rem] bg-purple/80 text-white text-xs tracking-wide hover:bg-grey hover:text-blue focus:bg-white focus:text-blue">
					<SelectValue placeholder={value} />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						{ 
							 items.map( item => <SelectItem key={item} value={item}>{item}</SelectItem>) 
						}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	)
}

export default SingleSelect;