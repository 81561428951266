import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/shadcn-ui/button';
import UserAuthForm from '@/components/shadcn-ui/user-auth-form';
import { Logo } from '@/components/Logo';
const bndImage =
	'https://www.ict.eu/sites/corporate/files/styles/twig_image_landscape_144_65_3840_1734/public/images/2025517991-huge%20copy.jpg?itok=cj72wm1J';

export default function Login() {
	return (
		<>
			<div className="container relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
				<Link
					to="/register"
					className={cn(
						buttonVariants({ variant: 'ghost', size: 'sm' }),
						'absolute right-4 top-4 md:right-8 md:top-8'
					)}
				>
					Registration
				</Link>
				<div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
					<div
						className="absolute inset-0 bg-cover"
						style={{
							backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 93, 93, 0.73)), url(${bndImage})`,
							backgroundPosition: 'center',
						}}
					/>
					<div className="relative z-20 flex items-center text-lg font-medium">
						<Logo className="w-64 h-auto" />
					</div>
				</div>
				<div className="lg:p-8">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
						<div className="flex flex-col space-y-2 text-center">
							<h1 className="text-2xl font-semibold tracking-tight">
								Business Continuity Platform
							</h1>
							<p className="text-sm text-muted-foreground">
								Enter you SecureStorageKey to login
							</p>
						</div>
						<UserAuthForm />
						<p className="px-8 text-center text-sm text-muted-foreground">
							By clicking continue, you agree to our{' '}
							<Link
								to="/terms-of-service"
								className="underline underline-offset-4 hover:text-primary"
							>
								Terms of Service
							</Link>{' '}
							and{' '}
							<Link
								to="/privacy-policy"
								className="underline underline-offset-4 hover:text-primary"
							>
								Privacy Policy
							</Link>
							.
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
