import { BubbleDataPoint } from 'chart.js';
export interface RisksBubbleDataPoint extends BubbleDataPoint {
	msp: string[];
	xTickLabel: string;
	yTickLabel: string;
}

export  const Consequence = [ 'Negligible', 'Minor', 'Moderate',  'Major', 'Catastrophic'];
// According documentation The numeration is vice versa (Likely=4 and Rare=1)
// to display the data in the chart, we reverse order (Likely=1 and Rare=4)
export const Likelihood = [ 'Likely', 'Possible', 'Unlikely', 'Rare' ];

interface RiskChartDataResponse {
	data: {
		consequence: string,
		details: { 
			likelihood: string,
			riskCount: number,
			riskValue: number,
			msp: string[]
		}[]
	}[],
	count: number,
	success: boolean,
	message: string
}
export const riskChartDataResponse:RiskChartDataResponse = {
	"data": [
	  {
		"consequence": "Negligible",
		"details": [
		  {
			"likelihood": "Rare",
			"riskCount": 2,
			"riskValue": 1,
			"msp": [
			  "Marsh Orr",
			  "Freeman Mcintyre"
			]
		  },
		  {
			"likelihood": "Possible",
			"riskCount": 1,
			"riskValue": 3,
			"msp": [
			  "Judy Oconnor"
			]
		  },
		  {
			"likelihood": "Likely",
			"riskCount": 1,
			"riskValue": 4,
			"msp": [
			  "Katie Hartman"
			]
		  }
		]
	  },
	  {
		"consequence": "Minor",
		"details": [
		  {
			"likelihood": "Rare",
			"riskCount": 2,
			"riskValue": 2,
			"msp": [
			  "Mathis Collier",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden",
			  "Ila Mcfadden"
			]
		  },
		  {
			"likelihood": "Possible",
			"riskCount": 2,
			"riskValue": 6,
			"msp": [
			  "Reese Graham",
			  "Bray Bartlett"
			]
		  },
		  {
			"likelihood": "Likely",
			"riskCount": 1,
			"riskValue": 8,
			"msp": [
			  "Franks Mathews"
			]
		  }
		]
	  },
	  {
		"consequence": "Moderate",
		"details": [
		  {
			"likelihood": "Unlikely",
			"riskCount": 3,
			"riskValue": 6,
			"msp": [
			  "Mclaughlin Flowers",
			  "Mari Rosa",
			  "Ferrell Reilly"
			]
		  },
		  {
			"likelihood": "Likely",
			"riskCount": 1,
			"riskValue": 12,
			"msp": [
			  "Fuentes Tyler"
			]
		  }
		]
	  },
	  {
		"consequence": "Major",
		"details": [
		  {
			"likelihood": "Unlikely",
			"riskCount": 1,
			"riskValue": 8,
			"msp": [
			  "Ashley Alvarado"
			]
		  },
		  {
			"likelihood": "Likely",
			"riskCount": 2,
			"riskValue": 16,
			"msp": [
			  "Robbie Kim",
			  "Goff Whitfield"
			]
		  }
		]
	  },
	  {
		"consequence": "Catastrophic",
		"details": [
		  {
			"likelihood": "Rare",
			"riskCount": 1,
			"riskValue": 5,
			"msp": [
			  "Mcdaniel Dominguez"
			]
		  },
		  {
			"likelihood": "Unlikely",
			"riskCount": 1,
			"riskValue": 10,
			"msp": [
			  "Blake Sexton"
			]
		  },
		  {
			"likelihood": "Likely",
			"riskCount": 2,
			"riskValue": 20,
			"msp": [
			  "Bowen Mccarty",
			  "Roberts Burgess"
			]
		  }
		]
	  }
	],
	"count": 5,
	"success": true,
	"message": "Successfully retreived 5 Risk Data."
}